import React from 'react';
import { AppBar, Badge, Stack, styled, Typography, useScrollTrigger } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useCart } from '../../../cart/CartContext';
import { icons } from '../../icons';
import { Link, ContentPreviewAlert } from '../../components';
import { useIsEventDay } from '../../events';
import { useNavigationStructure } from '../../routing';
import { useIsAdmin, useIsAgencyAdmin } from '../../hooks/authFunctions';
import { NavItem } from './NavItem';
import { AppMenu } from './AppMenu';
import { DonateModal } from './DonateModal';
import { StaticNavItem } from './StaticNavItem';

import LogoImage from '../../../assets/logos/UnitedWay_RochesterFingerLakes_logo_white.png';

const Logo = styled('img')(({ theme }) => ({
  display: 'inline-block',
  padding: '1rem',
  height: 'auto',

  [theme.breakpoints.up('xs')]: {
    maxWidth: '10rem!important',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '12rem!important',
  },
}));

export const AppHeader: React.FC = () => {
  const nav = useNavigationStructure();

  const isScrolled = useScrollTrigger({ threshold: 0, disableHysteresis: true });

  const isAdmin = useIsAdmin();
  const isAgencyAdmin = useIsAgencyAdmin();
  const isEventDay = useIsEventDay();
  const cart = useCart();

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{
        zIndex: '100',
        backgroundColor: isScrolled ? 'rgb(0 68 181 / 95%)' : 'rgb(0 68 181 / 70%)',
        boxShadow: isScrolled ? '0px 8px 10px -5px rgba(0,0,0,0.2)' : 'none',
        transition: 'backgroundColor 0.5s ease-in-out',
      }}
    >
      <ContentPreviewAlert />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          textTransform: 'uppercase',
          fontSize: '1rem',
          zIndex: '1001',
          letterSpacing: '.125rem',
          width: '100%',
          maxWidth: '1700px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Stack direction="row" alignItems="stretch">
          <Link to="/" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Logo
              src={LogoImage}
              alt="United Way of Greater Rochester and the Finger Lakes"
              className="mainLogo"
            />
          </Link>
          <DonateModal />
          <Stack
            direction="row"
            justifyContent="stretch"
            mx={3}
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          >
            {nav.getMenuItems().map(x => (
              <NavItem key={x.id} item={x} />
            ))}
            {isAdmin && <StaticNavItem to="/admin">Admin Dashboard</StaticNavItem>}
            {isAgencyAdmin && <StaticNavItem to="/agency/dashboard">My Agencies</StaticNavItem>}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            color="common.white"
            mx={2}
            mb={0}
            mt={0}
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          >
            <Link to="/search" color="common.white">
              <FontAwesomeIcon icon={icons.search} />
            </Link>
          </Typography>
          {isEventDay && !!cart.items.length && (
            <Link to="/roc-the-day/cart" mx={2} my={0} color="common.white">
              <Badge badgeContent={cart.items.length} color="primary">
                <FontAwesomeIcon icon={icons.cart} size="lg" />
              </Badge>
            </Link>
          )}

          <AppMenu />
        </Stack>
      </Stack>
    </AppBar>
  );
};
